<template>
  <div>
    <v-container>
      <v-row>
        <!-- 中心錢包 -->
        <v-col
          cols="6"
          class="px-1 pt-0"
        >
          <v-card
            color="card1"
            height="80"
          >
            <v-card-text class="px-0">
              <div class="text-center main">
                <div class="main__wallet">
                  <span class="primary--text subtitle-1">
                    {{ $t(`global.${currencySymbol}`) }}
                  </span>
                  <span class="title--text text-h5">{{ main }}</span>
                </div>
                <div
                  class="main__description primary--text font-weight-bold caption"
                >
                  {{ $t('myCenter.mainWallet') }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- 鎖定錢包 -->
        <v-col
          cols="6"
          class="px-1 pt-0"
        >
          <v-card
            color="card1"
            height="80"
          >
            <v-card-text class="px-0">
              <div class="text-center main">
                <div class="main__wallet">
                  <span class="secondary--text subtitle-1">
                    {{ $t(`global.${currencySymbol}`) }}
                  </span>
                  <span class="title--text text-h5">{{ locked }}</span>
                </div>
                <div
                  class="main__description secondary--text font-weight-bold d-flex align-center justify-center caption"
                  @click="dialog = true"
                >
                  {{ $t('myCenter.lockWallet') }}
                  <Icon
                    data="@icon/help.svg"
                    width="16"
                    height="16"
                    class="ml-1 cursor-pointer link--text"
                  />
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- 鎖定錢包 彈窗 -->
    <Alert
      :dialog="dialog"
      :width="280"
    >
      <div
        slot="title"
        class="title--text subtitle-1"
      >
        {{ $t('myCenter.lockWallet') }}
      </div>
      <div
        slot="text"
        class="px-6 text"
      >
        {{ $t('myCenter.lockWalletInfo') }}
      </div>
      <v-btn
        slot="btn"
        text
        color="primary"
        @click="dialog = false"
      >
        {{ $t('global.gotIt') }}
      </v-btn>
    </Alert>
  </div>
</template>

<script>
import Alert from '@/components/dialog'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    Alert,
  },

  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapState({
      main: state => state.wallet.main,
      locked: state => state.wallet.locked,
    }),

    ...mapGetters(['currencySymbol']),
  },

  mounted() {
    if (this.$route.name === 'transfer') return
    this.getAllWallet()
  },

  methods: {
    ...mapActions(['get_user_balance']),

    async getAllWallet() {
      await this.getUserWallet('player_balance')
      await this.getUserWallet('locked_balance')
    },

    /**
     * 取得使用者錢包 (中心錢包 / 鎖定錢包)
     * @date 2021-03-31
     * @param {string} walletcode "locked_balance"：鎖定錢包 - "player_balance"：主錢包、中心錢包 - "gf_ia_esport"：小艾電競錢包
     */
    async getUserWallet(walletcode) {
      await this.get_user_balance({ walletcode })
    },

  },
}
</script>

<style lang="scss" scoped>

</style>

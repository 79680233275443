<template>
  <div
    id="transfer"
    class="px-1"
  >
    <Toolbar>
      <div slot="btn">
        <v-btn
          icon
          @click="fetchWallet"
        >
          <Icon
            data="@icon/fetch.svg"
            width="24px"
            height="24px"
            class="title--text"
          />
        </v-btn>
      </div>
    </Toolbar>

    <v-container class="pt-56">
      <v-row>
        <v-col
          cols="12"
          class="bg p-sticky top-56"
        >
          <Wallet />
        </v-col>

        <!-- 免轉模式 -->
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-divider class="divider" />
          <v-container>
            <v-row>
              <v-col
                cols="6"
                class="d-flex align-center px-0"
              >
                <div class="d-flex align-center">
                  <!-- 切換轉換模式 -->
                  <label
                    for="mode"
                    class="subtitle-2 title--text font-weight-medium"
                  >
                    {{ $t('myCenter.automaticMode') }}
                  </label>
                  <v-switch
                    id="mode"
                    v-model="walletModeTrueFalse"
                    color="primary"
                    hide-details
                    class="pa-0 ma-0 ml-4"
                  />

                  <!-- loading allRetrieve -->
                  <v-progress-circular
                    v-show="loading.allRetrieve"
                    size="24"
                    width="2"
                    indeterminate
                    color="primary"
                  />
                </div>
              </v-col>

              <v-col
                cols="6"
                class="d-flex align-center justify-end px-0"
              >
                <!-- 全部回收 -->
                <v-btn
                  v-if="false"
                  :disabled="!hasBalanceCanRetrieve"
                  text
                  color="primary"
                  height="36"
                  width="79"
                  @click="openRetrieveAllWalletBalanceDialog"
                >
                  <span class="font-weight-medium">
                    {{ $t('myCenter.recycleAll') }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="divider" />
        </v-col>

        <!-- wallet loading 錢包已取得 -->
        <template v-if="walletLoading">
          <!-- 轉換金額區塊 -->
          <v-col
            v-show="!walletModeTrueFalse"
            cols="12"
            class="pb-4 pt-0"
          >
            <v-container>
              <v-row
                id="transfer-area"
                class="mb-1"
              >
                <v-col
                  cols="5"
                  class="pt-2 px-0 pb-0"
                  @click="openSelectListDialog('from')"
                >
                  <!-- 轉出 -->
                  <v-text-field
                    v-model="inputDisplay.from"
                    class="pb-2"
                    filled
                    outlined
                    readonly
                    dense
                    max-height="36"
                    append-icon="mdi-chevron-right"
                    hide-details
                  />
                  <!-- 轉出金額 (顯示) -->
                  <span
                    class="wallet-balance caption primary--text"
                  >
                    {{ $t(`global.${currencySymbol}`) + walletBalance.from }}
                  </span>
                </v-col>

                <v-col
                  cols="2"
                  class="pt-2 px-0 d-flex align-start justify-center"
                >
                  <v-btn
                    class="chang__wallet"
                    icon
                    @click="changWallet"
                  >
                    <Icon
                      data="@icon/arrow__right.svg"
                      width="16"
                      height="8"
                      class="primary--text"
                    />
                  </v-btn>
                </v-col>

                <v-col
                  cols="5"
                  class="pt-2 px-0 pb-0"
                  @click="openSelectListDialog('to')"
                >
                  <!-- 轉入 -->
                  <v-text-field
                    v-model="inputDisplay.to"
                    class="pb-2"
                    hide-details
                    filled
                    readonly
                    outlined
                    max-height="36"
                    dense
                    append-icon="mdi-chevron-right"
                  />
                  <span
                    class="wallet-balance caption primary--text"
                  >
                    {{ $t(`global.${currencySymbol}`) + (walletBalance.to === 0 ? '0.00' : walletBalance.to) }}
                  </span>
                </v-col>
              </v-row>

              <v-form
                ref="form"
                v-model="valid"
              >
                <v-row>
                  <v-col
                    id="transfer__amount"
                    cols="12"
                    class="px-0 pr-1 d-flex pb-1"
                  >
                    <!-- 輸入金額 -->
                    <v-text-field
                      v-model.trim.number="form.amount"
                      onkeyup="this.value=this.value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      type="number"
                      outlined
                      max-height="36"
                      dense
                      single-line
                      :rules="[rules.required, rules.overZero, rules.overMaxBalance]"
                      :placeholder="$t('form.missAmount')"
                      inputmode="decimal"
                    >
                      <div slot="append">
                        <!-- 梭哈 -->
                        <v-btn
                          outlined
                          color="primary"
                          class="showHand__btn"
                          dark
                          @click="form.amount = walletBalance.from"
                        >
                          <span class="primary--text caption">
                            {{ $t('global.showHand') }}
                          </span>
                        </v-btn>
                      </div>
                    </v-text-field>
                  </v-col>

                  <!-- 額度轉換 -->
                  <v-col
                    cols="12"
                    class="px-0 pt-0"
                  >
                    <v-btn
                      :loading="loading.transferBtn"
                      :disabled="!valid"
                      block
                      color="primary"
                      @click="walletTransfer(form)"
                    >
                      <span class="btnText--text">
                        {{ $t('global.transfer') }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-divider class="divider" />
          </v-col>

          <!-- 場館錢包 -->
          <v-col
            cols="12"
            class="pt-0"
          >
            <div>
              <span class="subtitle-2 title--text font-weight-mudium">
                {{ $t('global.vendor') + $t('global.wallet') }}
              </span>
            </div>

            <v-container class="mt-3">
              <v-row>
                <!-- 全部(任一錢包有錢) -->
                <v-col
                  v-show="hasBalanceCanRetrieve"
                  cols="12"
                  class="px-1 py-1"
                >
                  <!-- 錢包名稱(全部) -->
                  <v-card
                    class="d-flex align-center justify-center flex-column"
                    height="74"
                    :color="transferWallertAll ? 'secondary' : 'card1'"
                    :disabled="walletBalanceTotal < 0.01"
                    @click="recycleWalletBtn"
                  >
                    <div class="line-height-12">
                      <span
                        class="caption font-weight-bold"
                        :class="transferWallertAll ? 'black--text' : 'subtitle--text'"
                      >
                        {{ $t('global.all') }}
                      </span>
                    </div>

                    <!-- 金額 -->
                    <div class="line-height-16">
                      <span
                        class="subtitle-2"
                        :class="transferWallertAll ? 'black--text' : 'primary--text'"
                      >
                        {{ $t(`global.${currencySymbol}`) + moneySpec(Number(walletBalanceTotal)) }}
                      </span>
                    </div>
                  </v-card>
                </v-col>

                <v-col
                  v-for="(game, index) in walletList"
                  :key="game.wallet_code"
                  cols="6"
                  class="px-1 py-1"
                >
                  <!-- 所有錢包 -->
                  <v-card
                    class="p-relative"
                    height="74"
                    :color="activeWallet[game.wallet_code] ? 'secondary' : 'card1' "
                    :class="game.balance > 0 ? 'cursor-pointer' : '' "
                    :disabled="game.balance < 0.01"
                    @click="ToggleAddToTransferWallertList(game.wallet_code, game.balance, index)"
                  >
                    <!-- 錢包內容 -->
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          class="d-flex flex-column align-center justify-center h-74 px-0"
                        >
                          <!-- 錢包名稱 -->
                          <div class="line-height-12">
                            <span
                              class="caption font-weight-bold"
                              :class="activeWallet[game.wallet_code] ? 'black--text' : 'subtitle--text'"
                            >
                              {{ game.wallet.name }}
                            </span>
                          </div>

                          <!-- 金額 -->
                          <div class="line-height-16">
                            <span
                              class="subtitle-2 font-weight-bold"
                              :class="[!activeWallet[game.wallet_code] && game.balance > 0 ? 'primary--text' : 'subtitle--text', { 'black--text': activeWallet[game.wallet_code]} ] "
                            >
                              {{ $t(`global.${currencySymbol}`) + moneySpec(Number(game.balance)) }}
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                v-if="transferWalletList.length > 0"
                class="p-sticky bottom-0 bg"
              >
                <v-col
                  cols="5"
                  class="px-1"
                >
                  <!-- 取消選取 -->
                  <v-btn
                    depressed
                    block
                    color="card1"
                    height="44"
                    @click="clearSeleted"
                  >
                    <span class="title--text subtitle-2 font-weight-medium">
                      {{ $t('global.deselect') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="7"
                  class="px-1"
                >
                  <!-- 批次回收 -->
                  <v-btn
                    depressed
                    block
                    color="primary"
                    height="44"
                    @click="openMutiRetrieveDialog"
                  >
                    <span class="btnText--text subtitle-2 font-weight-medium">
                      {{ $t('myCenter.batchRecycle') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- 全部回收 -->
              <v-row
                v-show="transferWallertAll"
                class="p-sticky bottom-0 bg"
              >
                <v-col
                  cols="6"
                  class="px-1"
                >
                  <!-- 取消選取 -->
                  <v-btn
                    depressed
                    block
                    color="card1"
                    height="44"
                    @click="cancelRecycleWalletBtn"
                  >
                    <span class="title--text subtitle-1 font-weight-medium">
                      {{ $t('global.deselect') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="6"
                  class="px-1"
                >
                  <!-- (全部)回收 -->
                  <v-btn
                    depressed
                    block
                    color="primary"
                    height="44"
                    @click="openRetrieveAllWalletBalanceDialog"
                  >
                    <span class="btnText--text subtitle-1 font-weight-medium">
                      {{ $t('myCenter.recycle') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </template>

        <!-- wallet loading 錢包取得中 -->
        <template
          v-else
          class="text-center"
        >
          <v-col class="text-center mt-10">
            <v-progress-circular
              :size="70"
              :width="4"
              color="primary"
              indeterminate
            />
          </v-col>
        </template>
      </v-row>
    </v-container>

    <!-- 選擇 轉換錢包 彈窗 -->
    <SelectList :status="selectDialog.status">
      <v-row
        slot="title"
        class="p-sticky bg top-0 px-3 text-h6 title--text font-weight-medium"
      >
        <v-col class="">
          {{ $t('myCenter.selectVendorWallet') }}
        </v-col>
      </v-row>

      <!-- LIST -->
      <v-row slot="list">
        <!-- 中心錢包 -->
        <v-col
          cols="12"
          :class="[
            {
              secondary:
                form[`${selectDialog.type}_walletcode`] === 'player_balance',
            },
            'rounded'
          ]"
          @click="
            setWalletCode({
              walletCode: 'player_balance',
              name: $t('myCenter.mainWallet'),
              type: selectDialog.type,
            })
          "
        >
          <div class="px-3">
            {{ $t('myCenter.mainWallet') }}
          </div>
        </v-col>

        <!-- LOOP API 錢包 -->
        <v-col
          v-for="game in selectDialog.list"
          :key="game.wallet_code"
          cols="12"
          :class="[
            {
              secondary:
                form[`${selectDialog.type}_walletcode`] === game.wallet_code,
            },
            'rounded'
          ]"
          @click="
            setWalletCode({
              walletCode: game.wallet_code,
              name: game.wallet.name,
              type: selectDialog.type,
            })
          "
        >
          <div class="px-3">
            {{ game.wallet.name }}
          </div>
        </v-col>
      </v-row>
    </SelectList>

    <!-- dialog -->
    <Dialog
      :dialog="dialog.status"
      :width="280"
      :height="140"
    >
      <!-- 全部回收 -->
      <template v-if="dialog.type === 'allRetrieve'">
        <div
          slot="text"
          class="px-6"
        >
          {{ $t('flashMessage.allWalletTransferToMainWallet') }}
        </div>

        <v-btn
          slot="btn"
          text
          @click="resetDialog"
        >
          <span class="subtitle--text">
            {{ $t('global.cancel') }}
          </span>
        </v-btn>
        <v-btn
          slot="btn"
          text
          color="primary"
          @click="retrieveAllWalletBalance"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>

      <!-- 批次收回 -->
      <template v-if="dialog.type === 'retrieve'">
        <div
          slot="text"
          class="px-6 text-text"
        >
          {{ $t('flashMessage.recycleAll') }}
        </div>

        <v-btn
          slot="btn"
          text
          @click="resetDialog"
        >
          <span class="subtitle--text">
            {{ $t('global.cancel') }}
          </span>
        </v-btn>
        <v-btn
          slot="btn"
          text
          color="primary"
          @click="mutiRetrieve"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>

      <!-- 是否開啟免轉 (尚有餘額) -->
      <template v-if="dialog.type === 'transfer'">
        <div
          slot="text"
          class="px-6"
        >
          {{ $t('flashMessage.otherWalletsHaveBalances') }}
        </div>

        <v-btn
          slot="btn"
          text
          @click="setUserMode({ wallet_type: 'single' })"
        >
          <span class="subtitle--text">
            {{ $t('global.cancel') }}
          </span>
        </v-btn>
        <v-btn
          slot="btn"
          text
          color="primary"
          @click="resetDialog"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Toolbar from '@/components/base/Toolbar'
import Wallet from '@/components/base/Wallet'
import Dialog from '@/components/dialog'
import SelectList from '@/components/dialog/Select'
import { resStatus } from '@/utils/resUtils'
import { spec } from '@/utils/money'
import { clickEventInterval } from '../../../utils/event'

export default {
  components: {
    Toolbar,
    Wallet,
    SelectList,
    Dialog,
  },

  data: () => ({
    valid: false,
    mode: '',

    form: {
      from_walletcode: '', // 轉出錢包
      to_walletcode: '', // 輸入錢包
      amount: '', // 轉換金額
    },

    inputDisplay: {
      from: '',
      to: '',
    },

    walletList: [],
    transferWalletList: [],
    // 全包回收(錢包) 狀態
    transferWallertAll: null,

    selectDialog: {
      type: '',
      status: false,
      list: [],
    },

    walletBalance: {
      from: '',
      to: '',
    },

    loading: {
      transferBtn: false,
      allRetrieve: false,
    },

    dialog: {
      status: false,
      type: '',
    },

    // 目前 active 的錢包 (style)
    activeWallet: {},

    // wallet loading
    walletLoading: false,

    // 執行過後，是否可以再打 api
    eventLaunch: {
      walletFetch: null,
      retrieveAllWalletBalance: null,
      mutiRetrieve: null,
    },
  }),

  computed: {
    ...mapState({
      // 中心錢包
      userMainBalance: state => state.wallet.main,
      // 錢包模式
      walletMode: state => state.user.userData.member?.wallet_type,
      settings: state => state.websiteData.settings,

    }),

    ...mapGetters(['currencySymbol']),

    walletModeTrueFalse: {
      get() {
        return this.walletMode === 'single'
      },
      set(newStatue) {
        const walletType = newStatue ? 'single' : 'transfer'
        this.setUserMode({ wallet_type: walletType })

        if (walletType === 'transfer' && this.hasBalanceCanRetrieve) { return this.openSetUserModeDialog(walletType) }
      },
    },

    /**
     * 輸入轉換金額 驗證
     * - 必填
     * - 不得高於目前轉出的錢包餘額
     * @date 2021-04-14
     * @returns {string} 警示文字
     */
    rules() {
      return {
        required: value => !!value || this.$t('form.missAmount'),
        overZero: value => value > 0 || this.$t('global.amount') + this.$t('global.fail'),
        overMaxBalance: value =>
          value <= this.walletBalance.from ||
          this.$t('form.errorAmountNoMoney'),
      }
    },

    /**
     * 場館錢包是否有可以回收的項目 全部回收btn
     * @date 2021-04-14
     * @returns {object} 可以回收的場館錢包
     */
    hasBalanceCanRetrieve() {
      const zero = ['0.00', 0, '0']
      return this.walletList.find(item => !zero.includes(item.balance))
    },

    /**
     * 大於 0 的錢包加總
     * @date 2021-09-07
     * @returns {number} 加總金額
     */
    walletBalanceTotal() {
      return this.walletList.reduce((acc, cur) => acc + Number(cur.balance), 0)
    },
  },

  watch: {
    'form.from_walletcode'(newWalletCode) {
      if (this.$refs.form) this.$refs.form.reset()
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('global.transfer'))
    this.userDataRefresh()

    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_user_balance',
      'get_user_wallet_list',
      'set_user_wallet_mode',
      'set_user_data',
      'user_transfer_wallet',
      'show_alert',
      'set_progress_status',
      'user_retrieve_all_wallet_balance',
      'multiple_wallet_transfer_to_main_wallet',
      'set_main_balance',
      'set_locked_balance',
      'user_data_refresh',
    ]),

    ...mapMutations(['SET_USER_WALLET_TYPE']),

    /**
     * 使用者資料更新 (成功)
     * - 取得場館錢包
     * - 取得中心/鎖定 錢包
     * @date 2021-10-27
     */
    userDataRefreshSuccess() {
      this.getUserWalletList()
      this.get_user_balance({ walletcode: 'player_balance' })
      this.get_user_balance({ walletcode: 'locked_balance' })
    },

    /**
     * 使用者資料更新
     * @date 2021-10-27
     */
    async userDataRefresh() {
      const res = await this.user_data_refresh()
      resStatus(res, this.userDataRefreshSuccess)
    },

    /**
     * 更新所有錢包狀態 for toolbar
     * @date 2021-04-14
     */
    fetchWallet() {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.walletFetch.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.walletFetch.setStatus(false)

      this.get_user_balance({ walletcode: 'player_balance' })
      this.get_user_balance({ walletcode: 'locked_balance' })
      this.getUserWalletList()
    },

    /**
     * 設置默認的 轉出/轉入 錢包
     * @date 2021-04-14
     * @param {object} firstWallet 場館錢包的第一個錢包
     */
    setDefaultFromTo(firstWallet) {
      this.setWalletCode({
        walletCode: 'player_balance',
        name: this.$t('myCenter.mainWallet'),
        type: 'from',
      })

      this.setWalletCode({
        walletCode: firstWallet.wallet_code,
        name: firstWallet.wallet.name,
        type: 'to',
      })
    },

    /**
     * 取得使用者所有遊戲錢包 (成功)
     * @date 2021-09-24
     * @param {any} data
     * @returns {any}
     */
    getUserWalletListSuccess(data) {
      const { game, player } = data

      // 更新中心錢包金額 @player
      const mainBalance = player.find(item => item.wallet_code === 'player_balance')
      const lockedBalance = player.find(item => item.wallet_code === 'locked_balance')
      this.set_main_balance(Number(mainBalance.balance))
      this.set_locked_balance(Number(lockedBalance.balance))

      this.walletList = game
      this.selectDialog.list = game
      this.setDefaultFromTo(game[0])

      this.walletLoading = true
    },

    /**
     * 取得使用者所有遊戲錢包
     * @date 2021-09-24
     */
    async getUserWalletList() {
      this.walletLoading = false
      const res = await this.get_user_wallet_list()
      resStatus(res, this.getUserWalletListSuccess, this.walletTransferFail)
    },

    openSetUserModeDialog(type) {
      this.dialog.type = type
      this.dialog.status = true
    },

    /**
     * 設置使用者使用模式 (成功)
     * - 重新取得使用者資料
     * @date 2021-10-27
     * @param {any} data
     * @returns {any}
     */
    setUserModeSuccess(data) {
      this.clearSeleted()

      this.cancelRecycleWalletBtn()

      this.userDataRefresh()
      if (data.wallet_type === 'single') this.resetDialog()
    },

    /**
     * 設置使用者使用模式 (一般/免轉)
     * @date 2021-04-13
     * @param {string} walletModel 錢包模式 'transfer' : 'single'
     */
    async setUserMode(walletModel) {
      const res = await this.set_user_wallet_mode(walletModel)
      resStatus(res, this.setUserModeSuccess)
    },

    openSelectListDialog(type) {
      this.selectDialog.type = type
      this.selectDialog.status = true
    },

    /**
     * 檢查場館錢包，是否符合原則
     * - 如果不是重覆項目
     *   - 必須其一為「中心錢包」 (不可錢包互轉)
     * - 轉出/轉入為同場館 (皆為中心錢包)
     * - 轉出/轉入為同場館 (不為中心錢包)
     * @date 2021-04-13
     * @param {string} nowSetwalletCode 目前設置錢包的 錢包代碼
     * @param {string} nowSettype 目前設置錢包的狀態 (from / to)
     * @returns {function} 執行另一個狀態的設置，若非重覆則跳出
     */
    checkSelectedItem(nowSetwalletCode, nowSettype) {
      const otherType = nowSettype === 'from' ? 'to' : 'from'
      const doubleSelect =
        this.form[`${otherType}_walletcode`] === nowSetwalletCode
      const selectWalletIsUserMainWallet = nowSetwalletCode === 'player_balance'

      if (!doubleSelect) {
        if (this.walletBalance.from && this.walletBalance.to) {
          const copyFromNtoAry = [
            this.form.from_walletcode,
            this.form.to_walletcode,
          ]

          if (!copyFromNtoAry.includes('player_balance')) {
            this.setWalletCode({
              walletCode: 'player_balance',
              name: this.$t('myCenter.mainWallet'),
              type: otherType,
            })
          }
        } else return false
      } else {
        const firstWallet = this.walletList[0]

        selectWalletIsUserMainWallet
          ? this.setWalletCode({
            walletCode: firstWallet.wallet_code,
            name: firstWallet.wallet.name,
            type: otherType,
          })
          : this.setWalletCode({
            walletCode: 'player_balance',
            name: this.$t('myCenter.mainWallet'),
            type: otherType,
          })
      }
    },

    /**
     * 設置目前選擇的場館錢包 (轉出/轉入)
     * @date 2021-04-13
     * @param {object} data {walletCode: 'player_balance',name: '場館名稱' }
     */
    setWalletCode(data) {
      const { walletCode, name, type } = data
      this.form[`${type}_walletcode`] = walletCode
      this.inputDisplay[type] = name
      this.selectDialog.status = false

      // 設置顯示的金額
      this.dispalySelectWalletBalance(walletCode, type)

      // 檢查轉出/轉入是否符合原則
      this.checkSelectedItem(walletCode, type)

      this.selectDialog.type = ''
      this.transferWalletList = []
    },

    /**
     * 設置目前選擇的 (轉出/轉入) 錢包餘額
     * @date 2021-04-13
     * @param {string} walletCode 場館錢包代號
     * @param {string} type 轉出 || 轉入
     */
    dispalySelectWalletBalance(walletCode, type) {
      if (walletCode === 'player_balance') {
        this.walletBalance[type] = this.userMainBalance
        return false
      }

      const wallet = this.walletList.filter(
        wallet => wallet.wallet_code === this.form[`${type}_walletcode`],
      )[0]
      this.walletBalance[type] = wallet.balance
    },

    walletTransferSuccess() {
      // 更新中心/鎖定 錢包
      this.fetchWallet()

      // 更新場館錢包
      this.getUserWalletList()

      // AMOUNT RESET
      this.$refs.form.reset()

      this.show_alert({
        icon: 'done',
      })

      // 轉換錢包 && active style 重置
      this.transferWalletList = []
      this.activeWallet = {}

      // 全部回收模式重置
      this.cancelRecycleWalletBtn()
      this.resetDialog()
    },

    walletTransferFail() {
      this.show_alert({
        icon: 'fail',
      })
    },

    /**
     * 單一錢包轉帳
     * @date 2021-04-14
     * @param {object} form 錢包換轉表單
     */
    async walletTransfer(form) {
      this.loading.transferBtn = true
      const res = await this.user_transfer_wallet(form)
      this.loading.transferBtn = false
      resStatus(res, this.walletTransferSuccess, this.walletTransferFail)
    },

    /**
     * 轉出 / 轉入 互換功能
     * @date 2021-04-14
     */
    changWallet() {
      const from = {
        walletCode: this.form.to_walletcode,
        name: this.inputDisplay.to,
        type: 'from',
      }
      const to = {
        walletCode: this.form.from_walletcode,
        name: this.inputDisplay.from,
        type: 'to',
      }

      this.setWalletCode(from)
      this.setWalletCode(to)
    },

    openRetrieveAllWalletBalanceDialog() {
      this.dialog.type = 'allRetrieve'
      this.dialog.status = true
    },

    /**
     * 全部回收
     * - 操作 loading
     * @date 2021-04-14
     */
    async retrieveAllWalletBalance() {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.retrieveAllWalletBalance.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.retrieveAllWalletBalance.setStatus(false)

      this.loading.allRetrieve = true
      const res = await this.user_retrieve_all_wallet_balance()
      this.loading.allRetrieve = false
      resStatus(res, this.walletTransferSuccess, this.walletTransferFail)
    },

    /**
     * 批次回收
     * - 將要轉換的 form，陣列化，傳送給 actions (axios.all)
     * @date 2021-04-14
     * @returns {function} 成功 / 失敗 執行方法
     */
    async mutiRetrieve() {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.mutiRetrieve.getStatus()) return false

      // 數秒後回複狀態
      this.eventLaunch.mutiRetrieve.setStatus(false)

      const transferWalletList = this.transferWalletList
      let apiRequestAry = []

      transferWalletList.forEach(item => {
        const form = {
          from_walletcode: item[0],
          to_walletcode: 'player_balance',
          amount: item[1],
        }
        apiRequestAry = [...apiRequestAry, { ...form }]
      })

      const res = await this.multiple_wallet_transfer_to_main_wallet(
        apiRequestAry,
      )
      const failInclude = res.find(item => item.status === '0')
      this.resetDialog()
      if (!failInclude) return this.walletTransferSuccess()
      return this.walletTransferFail()
    },

    resetDialog() {
      this.dialog.status = false
      this.dialog.type = ''
      this.transferWalletList = []
    },

    openMutiRetrieveDialog() {
      this.dialog.type = 'retrieve'
      this.dialog.status = true
    },

    scrollToWalletArea() {
      window.scroll({
        top: 180,
        left: 0,
        behavior: 'smooth',
      })
    },

    /**
     * 新增(移除)轉換錢包 (一般錢包)
     * @date 2021-09-07
     * @param {any} walletCode 錢包代碼
     * @param {any} walletBalance 錢包金額
     */
    ToggleAddToTransferWallertList(walletCode, walletBalance, index) {
      // 清除 (全部回收) 錢包
      this.cancelRecycleWalletBtn()

      const isInside = this.transferWalletList.find((item) => item[0] === walletCode)

      if (!isInside) {
        // 如果項目不在轉換錢包內 => 新增
        this.activeWallet[walletCode] = true
        this.transferWalletList = [...this.transferWalletList, [walletCode, walletBalance]]
      } else {
        // 如果項目在轉換錢包內 => 刪除
        const insideIndex = this.transferWalletList.indexOf(isInside)
        this.transferWalletList.splice(insideIndex, 1)
        delete this.activeWallet[walletCode]
      }
      // Scroll
      if (index < 6 && this.transferWalletList.length > 0 && this.hasBalanceCanRetrieve && this.walletMode === 'single') this.scrollToWalletArea()
    },

    /**
     * 取消選取 (一般錢包)
     * @date 2021-09-07
     */
    clearSeleted() {
      this.activeWallet = {}
      this.transferWalletList = []
    },

    /**
     * 全部回收 (錢包)
     * @date 2021-09-07
     */
    recycleWalletBtn() {
      this.clearSeleted()
      this.transferWallertAll = !this.transferWallertAll

      if (this.transferWallertAll && this.walletMode === 'single') this.scrollToWalletArea()
    },

    /**
     * 取消選取 (全部回收錢包)
     * @date 2021-09-07
     */
    cancelRecycleWalletBtn() {
      this.transferWallertAll = false
    },

    moneySpec(money) {
      return spec(money)
    },
  },
}
</script>

<style lang="scss" scoped>
.lt-6 {
	top: 3px;
	left: 3px;
}

.h-74 {
	height: 74px;
}

.line-height-12 {
	line-height: 12px;
}

.line-height-16 {
	line-height: 16px;
}

.bottom-0 {
	bottom: 0;
}

.top-0 {
	top: 0;
}

.top-56 {
	top: 56px;
	z-index: 1;
}

.wallet-balance {
	line-height: 12px;
}
</style>

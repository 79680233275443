<template>
  <!-- 取款 -->
  <div class="pt-56">
    <v-container>
      <v-row>
        <v-col cols="12">
          <Wallet />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <v-divider class="card-1" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="withdrawalToBank"
        >
          <div class="withdrawalToBank__title title--text font-weight-medium">
            {{ $t('myCenter.withdrawToBankCard') }}
          </div>
          <v-container class="mt-4">
            <v-row>
              <!-- 使用者銀行卡列表 -->
              <v-col
                v-for="card in userBankCardList"
                :key="card._id"
                cols="12"
                class="py-1 px-0"
              >
                <v-btn
                  block
                  :text="form.bank_no === card.bank_no ? false : true"
                  :outlined="form.bank_no === card.bank_no ? true : false"
                  :color="form.bank_no === card.bank_no ? 'primary' : ''"
                  class="p-relative"
                  :class="[
                    form.bank_no === card.bank_no
                      ? 'primary--text'
                      : 'subtitle--text card1',
                  ]"
                  @click="setUserBankCardNumber(card.bank_no)"
                >
                  <div class="text-center d-flex">
                    <v-img
                      :src="baseImgUrl + card.image"
                      class="mr-2"
                      height="18"
                      width="18"
                      contain
                    />
                    <div class="d-flex align-center">
                      {{ card.bank_name }}
                    </div>
                  </div>
                  <div class="d-flex align-center bank_no">
                    {{ card.bank_no.substr(card.bank_no.length - 4, 4) }}
                  </div>
                </v-btn>
              </v-col>

              <!-- 新增卡片 -->
              <v-col
                v-if="userBankCardList.length < 3"
                cols="12"
                class="py-1 px-0"
              >
                <v-btn
                  to="/user/add-card"
                  block
                  text
                  class="card1"
                >
                  <div class="text-center d-flex">
                    <div class="d-flex align-center subtitle--text">
                      <v-icon
                        color="secondary"
                        class="mr-2"
                      >
                        mdi-plus-circle
                      </v-icon>
                      {{ $t('flashMessage.addCard') }}
                    </div>
                  </div>
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                class="pt-5 pb-0 px-0"
              >
                <v-divider class="card-1" />
              </v-col>

              <v-col
                cols="12"
                class="px-0"
              >
                <div
                  class="withdrawalToBank__title title--text font-weight-medium"
                >
                  {{ $t('global.withdraw') + $t('global.amount') }}
                </div>
                <v-container>
                  <v-row>
                    <v-col
                      cols="6"
                      class="px-0 pb-0"
                    >
                      <v-form
                        ref="form"
                        v-model="valid"
                      >
                        <v-text-field
                          v-model="form.bank_no"
                          class="d-none"
                          :rules="[rules.amount.required]"
                        />
                        <!-- 取款金額 -->
                        <v-text-field
                          v-model.trim="form.amount"
                          onkeyup="this.value=this.value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          max-height="36"
                          dense
                          :placeholder="$t('form.missAmount')"
                          single-line
                          outlined
                          required
                          clear-icon="mdi-close-circle"
                          clearable
                          type="number"
                          inputmode="decimal"
                          :rules="[
                            rules.amount.required,
                            rules.amount.min,
                            rules.amount.overBlance,
                            rules.amount.overCount,
                            rules.amount.max,
                          ]"
                        >
                          <div slot="clear-icon">
                            <v-icon>mdi-close-circle</v-icon>
                          </div>
                        </v-text-field>
                      </v-form>
                    </v-col>

                    <v-col
                      cols="6"
                      class="px-0 pb-0 caption comment--text"
                    >
                      <div class="pl-4 d-flex flex-column justify-start">
                        <span>
                          {{ $t('myCenter.singleWithdrawRange') }}
                        </span>
                        <span>
                          {{
                            $t(`global.${currencySymbol}`) +
                              amount.min_amount +
                              '~' +
                              $t(`global.${currencySymbol}`) +
                              amount.max_amount
                          }}
                        </span>
                      </div>
                    </v-col>

                    <!-- 今日可用額度 -->
                    <v-col
                      cols="12"
                      class="comment--text caption pt-0 px-0"
                    >
                      <!-- 可以次數 -->
                      <div>
                        <span class="mr-2">{{ $t('myCenter.withdrawAvailableTime') }}</span>
                        <span class="primary--text">
                          {{ amount.left_count }}
                        </span>
                      </div>

                      <!-- 可用額度 -->
                      <div class="mt-1">
                        <span class="mr-2">{{ $t('myCenter.withdrawAvailableAmount') }}</span>
                        <span class="primary--text">
                          {{ $t(`global.${currencySymbol}`) + amount.left_amount }}
                        </span>
                      </div>
                    </v-col>
                    <v-col class="pa-0 pt-5">
                      <v-btn
                        block
                        color="primary"
                        height="44"
                        :disabled="valid ? false : true"
                        @click="setDialogState(true, 'withdrawals')"
                      >
                        <span class="subtitle-1 btnText--text">
                          {{ $t('global.withdraw') }}
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog (完善基本資料/取款) -->
    <Dialog
      :dialog="dialog.status"
      :width="280"
      :height="dialog.type === 'withdrawals' ? 202 : 150"
    >
      <!-- 如果要提款 -->
      <template v-if="dialog.type === 'withdrawals'">
        <div slot="title">
          <span class="subtitle-1 title--text">
            {{ $t('form.misswithdrawPassword') }}
          </span>
        </div>

        <div
          slot="text"
          class="px-6"
        >
          <span class="title--text caption">
            {{ $t('myCenter.withdrawPassword') }}
          </span>
          <v-form
            ref="pwdForm"
            v-model="pwdValid"
            lazy-validation
          >
            <!-- 輸入 提款密碼 -->
            <v-text-field
              v-model="form.withdrawal_password"
              type="password"
              class="mt-2"
              autofocus
              max-height="36"
              dense
              :placeholder="$t('form.missPassword')"
              single-line
              outlined
              required
              clear-icon="mdi-close-circle"
              clearable
              :rules="[rules.pwd.required]"
            />
          </v-form>
        </div>

        <div slot="btn">
          <v-btn
            color="grey"
            text
            @click="setDialogState()"
          >
            {{ $t('global.cancel') }}
          </v-btn>

          <!-- 使用者取款 submit -->
          <v-btn
            :disabled="!pwdValid ? true : false"
            color="primary"
            text
            @click="userWithdrawals(form)"
          >
            {{ $t('global.confirmation') }}
          </v-btn>
        </div>
      </template>

      <!-- 如果沒有完善基本資料 -->
      <template v-if="dialog.type === 'baseInfoHaveToCheck'">
        <div
          slot="text"
          class="text px-6"
        >
          {{ $t('myCenter.firstAddBankCard') }}
        </div>

        <v-btn
          slot="btn"
          to="/user/personal"
          text
          color="primary"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/dialog'
import Wallet from '@/components/base/Wallet'
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '../../../utils/resUtils'
import { clickEventInterval } from '../../../utils/event'

export default {
  components: { Dialog, Wallet },

  data: () => ({
    valid: true,
    pwdValid: true,

    form: {
      bank_no: '', // 銀行卡號
      amount: '', // 提款金額
      withdrawal_password: '', // 提款密碼
    },

    userBankCardList: [],
    userBaseInfoStatus: false,

    amount: {
      left_amount: 0,
      left_count: 0,
      max_amount: 0,
      min_amount: 0,
    },

    dialog: {
      status: false,
      type: '',
    },

    // 執行過後，是否可以再打 api
    eventLaunch: {
      userWithdrawals: null,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'currencySymbol']),
    ...mapState({
      main: state => state.wallet.main,
      locked: state => state.wallet.locked,
      settings: state => state.websiteData.settings,
    }),

    rules() {
      return {
        amount: {
          required: value => !!value || this.$t('form.missAmount'),
          // 超過中心錢包
          overBlance: val =>
            val <= Number(this.main) ||
            this.$t('form.errorAmountNoMoney'),

          // 超過今日可用額度
          overCount: val =>
            val <= this.amount.left_amount ||
            this.$t('form.errorAmountDailyLimit'),

          min: value =>
            value >= this.amount.min_amount ||
            this.$t('form.errorAmountOutofRange', {
              min: this.amount.min_amount,
              max: this.amount.max_amount,
            }),

          max: value =>
            value <= this.amount.max_amount ||
            this.$t('form.errorAmountOutofRange', {
              min: this.amount.min_amount,
              max: this.amount.max_amount,
            }),
        },
        bankCardNo: {
          required: value => !!value || this.$t('form.missCardNo'),
        },
        pwd: {
          required: val => !!val || this.$t('form.missPassword'),
        },
      }
    },
  },

  watch: {
    userBaseInfoStatus(status) {
      if (status) return this.setDialogState(true, 'baseInfoHaveToCheck')
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('global.withdraw'))
    this.getUserBankList()

    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_user_bankcard_list',
      'user_withdrawals',
      'show_alert',
      'get_user_balance',
    ]),

    /**
     * 成功取得 取得使用者銀行卡列表
     * @date 2021-03-31
     * @param {object} 銀行卡列表資料
     */
    getUserBankListSuccess(data) {
      const {
        bankcards,
        completed_baseinfo,
        left_amount,
        left_count,
        max_amount,
        min_amount,
      } = data

      this.userBankCardList = bankcards
      this.userBaseInfoStatus = completed_baseinfo !== 'Y'
      this.amount.left_count = left_count
      this.amount.left_amount = left_amount
      this.amount.max_amount = max_amount
      this.amount.min_amount = min_amount
    },

    /**
     * 取得使用者銀行卡列表
     * @date 2021-03-31
     */
    async getUserBankList() {
      const userBankCardList = await this.get_user_bankcard_list()
      resStatus(userBankCardList, this.getUserBankListSuccess)
    },

    setUserBankCardNumber(bank_no) {
      this.form.bank_no = bank_no
    },

    setDialogState(status = false, typeName = '') {
      if (!status) {
        this.$refs.pwdForm.reset()
      }
      this.dialog.status = status
      this.dialog.type = typeName
    },

    /**
     * 使用者取款 (成功)
     * @date 2021-08-04
     */
    userWithdrawalsSuccess() {
      this.show_alert({
        icon: 'done',
        text: this.$t('global.withdraw') + this.$t('global.success'),
      })
      this.getAllWallet()
      this.getUserBankList()
      this.setDialogState()
      this.$refs.form.reset()
    },

    /**
     * 使用者取款 (失敗)
     * @date 2021-08-04
     */
    userWithdrawalsFail() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('global.withdraw') + this.$t('global.fail'),
      })
      this.setDialogState()
      this.$refs.form.reset()
    },

    /**
     * 使用者取款
     * @date 2021-08-04
     * @param {object} form
     */
    async userWithdrawals(form) {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.userWithdrawals.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.userWithdrawals.setStatus(false)

      const res = await this.user_withdrawals(form)
      resStatus(res, this.userWithdrawalsSuccess, this.userWithdrawalsFail)
    },

    async getAllWallet() {
      await this.getUserWallet('player_balance')
      await this.getUserWallet('locked_balance')
    },

    /**
     * 取得使用者錢包 (中心錢包 / 鎖定錢包)
     * @date 2021-03-31
     * @param {string} walletcode "locked_balance"：鎖定錢包 - "player_balance"：主錢包、中心錢包 - "gf_ia_esport"：小艾電競錢包
     */
    async getUserWallet(walletcode) {
      await this.get_user_balance({ walletcode })
    },
  },
}
</script>

<style lang="scss" scoped>
.bank_no {
	position: absolute;
	right: 0;
}
</style>
